import dede from './locales/dede.json'
import enen from './locales/enen.json'
import frfr from './locales/frfr.json'
import cscz from './locales/cscz.json'
import eses from './locales/eses.json'
import nlnl from './locales/nlnl.json'
import itit from './locales/itit.json'
import trtr from './locales/trtr.json'
import enus from './locales/enus.json'
import plpl from './locales/plpl.json'
import sksk from './locales/sksk.json'
import int  from './locales/int.json'

export const defaultLocale = 'int'

export const languages = {
    dede: dede,
    enen: enen,
    frfr: frfr,
    cscz: cscz,
    eses: eses,
    nlnl: nlnl,
    itit: itit,
    trtr: trtr,
    enus: enus,
    plpl: plpl,
    sksk: sksk,
    int: int,
}

//Languages
import { createI18n } from 'vue-i18n';
const messages = Object.assign(languages);

var currentLocale = 'int';

if(getCookie('portal_lang') != "") {
    currentLocale = getCookie('portal_lang');
} else if(navigator.language || navigator.userLanguage) {
    switch((navigator.language || navigator.userLanguage).toLowerCase()) {
        case 'de':      //German (Standard)
        case 'de-at':   //German (Austria)
        case 'de-de':   //German (Germany)
        case 'de-li':   //German (Lichtenstein)
        case 'de-lu':   //German (Luxembourg)
        case 'de-ch':   //German (Switzerland)
            currentLocale = 'dede';
            break;
        case 'en-gb':   //English (United Kingdom)
            currentLocale = 'enen';
            break;
        case 'fr':      //French (Standard)
        case 'fr-be':   //French (Belgium)
        case 'fr-ca':   //French (Canada)
        case 'fr-fr':   //French (France)
        case 'fr-lu':   //French (Luxembourg)
        case 'fr-mc':   //French (Monaco)
        case 'fr-ch':   //French (Switzerland)
            currentLocale = 'frfr';
            break;
        case 'cs':      //Czech
            currentLocale = 'cscz';
            break;
        case 'es':      //Spanish"
        case 'es-ar':   //Spanish (Argentina)
        case 'es-bo':   //Spanish (Bolivia)
        case 'es-cl':   //Spanish (Chile)
        case 'es-co':   //Spanish (Colombia)
        case 'es-cr':   //Spanish (Costa Rica)
        case 'es-do':   //Spanish (Dominican Republic)
        case 'es-ec':   //Spanish (Ecuador)
        case 'es-sv':   //Spanish (El Salvador)
        case 'es-gt':   //Spanish (Guatemala)
        case 'es-hn':   //Spanish (Honduras)
        case 'es-mx':   //Spanish (Mexico)
        case 'es-ni':   //Spanish (Nicaragua)
        case 'es-pa':   //Spanish (Panama)
        case 'es-py':   //Spanish (Paraguay)
        case 'es-pe':   //Spanish (Peru)
        case 'es-pr':   //Spanish (Puerto Rico)
        case 'es-es':   //Spanish (Spain)
        case 'es-uy':   //Spanish (Uruguay)
        case 'es-ve':   //Spanish (Venezuela)
        case 'ca':      //Catalan
            currentLocale = 'eses';
            break;
        case 'nl':      //Dutch (Standard)
        case 'nl-be':   //Dutch (Belgian)
        case 'nl-nl':   //Dutch (Belgian)
            currentLocale = 'nlnl';
            break;
        case 'it':      //Italian (Standard)
        case 'it-ch':   //Italian (Switzerland)
            currentLocale = 'itit';
            break;
        case 'en-us':   //English (United States)
            currentLocale = 'enus';
            break;
        case 'pl':      //Polish
            currentLocale = 'plpl';
            break;
        case 'tr-tr':      //Turkish
            currentLocale = 'trtr';
            break;
        case 'sk':      //Slovak
            currentLocale = 'sksk';
            break;
        default:
            currentLocale = defaultLocale;
            break;
    }
}else if (getCookie('geoip_website') != "") {
    currentLocale = getCookie('geoip_website');
} else {
    currentLocale = defaultLocale;
}

let availableLanguages = Object.keys(messages);

if(window.location.href.includes('localhost')){
    currentLocale = 'enus';
}

if(!availableLanguages.includes(currentLocale)){
    currentLocale = defaultLocale;
}

const i18n = createI18n({
    locale: currentLocale,
    fallbackLocale: defaultLocale,
    messages
})

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default i18n;