import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import axios from 'axios';

const routes = [
/*     { //Registration
        path: '/registration/:shop?',
        name: 'Registration',
        component: () => import(/* webpackChunkName: "registration"  '../views/Registration.vue'),
        meta:{
            sidebar:false,
            visible: [],
        }
    }, 
*/
    { //Registration_new
        path: '/registration/:shop?',
        name: 'Registration_new',
        component: () => import(/* webpackChunkName: "registration" */ '../views/Registration_new.vue'),
        meta:{
            sidebar:false,
            visible: [],
        }
    },
    { //Login
        path: '/login/:shop?/',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta:{
            sidebar:false,
            visible: [],
        }
    },
    { //Reset Password
        path: '/reset-password/:hash:',
        name: 'PasswordReset',
        component: () => import(/* webpackChunkName: "PasswordReset" */ '../views/PasswordReset.vue'),
        meta:{
            sidebar:false,
            visible: [],
        }
    },
    { //Dashboard
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        meta: {
            visible: [],
        }
    },
    { // First Steps REG 
        path: '/first-steps/',
        name: 'FirstSteps',
        component: () => import(/* webpackChunkName: "firststeps" */ '../views/FirstSteps.vue'),
        meta: {
            visible: [],
        } 
    },
    { //Info - Downloads
        path: '/info/downloads/',
        name: 'Info_PartnerDownloads',
        component: () => import(/* webpackChunkName: "info_downloads" */ '../views/Info_PartnerDownloads.vue'),
        meta: {
            visible: ['partner'],
        }
    },
    { //Orders
        path: '/orders/',
        name: 'Orders',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
        meta: {
            visible: [],
        }
    },
    { //Orders
        path: '/invoices/',
        name: 'Invoices',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Invoices.vue'),
        meta: {
            visible: [],
        }
    },
    { //Orders
        path: '/order/:id:/',
        name: 'OrderDetail',
        component: () => import(/* webpackChunkName: "orders" */ '../views/Orders_Detail.vue'),
        meta: {
            visible: [],
        }
    },
    { //Partner Website
        path: '/website/',
        name: 'PartnerWebsite',
        component: () => import(/* webpackChunkName: "website" */ '../views/PartnerWebsite.vue'),
        meta: {
            visible: ['partner'],
        }
    },
    { //Products
        path: '/products/',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue'),
        meta: {
            visible: [],
        }
    },
    { //References
        path: '/references/',
        name: 'References',
        component: () => import(/* webpackChunkName: "references" */ '../views/References.vue'),
        meta: {
            visible: [],
        }
    },
    { //Settings
        path: '/settings/',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        meta: {
            visible: [],
        }
    },
    { //Employees
        path: '/settings/employees',
        name: 'Employees',
        component: () => import(/* webpackChunkName: "employees" */ '../views/Employees.vue'),
        meta: {
            visible: [],
        }
    },
    { //Trainings
        path: '/trainings/',
        name: 'Trainings',
        component: () => import(/* webpackChunkName: "trainings" */ '../views/Trainings.vue'),
        meta: {
            visible: []
        }
    },
    { //Support
        path: '/tickets/',
        name: 'Tickets',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Ticket_Overview.vue'),
        meta: {
            visible: [],
        }
    },
    { //Support
        path: '/ticket/:id:/',
        name: 'Ticket',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets_Detail.vue'),
        meta: {
            visible: [],
        }
    },
    { //Messages
        path: '/messages/',
        name: 'MessageCenter',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/MessageCenter.vue'),
        meta: {
            visible: [],
        }
    },
    { //Messages
        path: '/message/:id:/',
        name: 'MessageDetail',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Messages_Detail.vue'),
        meta: {
            visible: [],
        }
    },
    { //Messages
        path: '/new-ticket/',
        name: 'Ticket_New',
        component: () => import(/* webpackChunkName: "Ticket_new" */ '../views/Ticket_New.vue'),
        meta: {
            visible: [],
        }
    },
    { //Messages
        path: '/new-ticketv2/',
        name: 'Ticket_NewV2',
        component: () => import(/* webpackChunkName: "Ticket_new" */ '../views/Ticket_NewV2.vue'),
        meta: {
            visible: [],
        }
    },
    {
        path: '/new-ticket/statistic/',
        name: 'Ticket_New_Statistic',
        component: () => import(/* webpackChunkName: "Ticket_new" */ '../views/Ticket_New_Statistic.vue'),
        meta: {
            visible: ['ma'],
        }
    },
    { //Advantages
        path: '/advantages/',
        name: 'Advantages',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Advantages.vue'),
        meta: {
            visible: ['partner', 'ma'],
        }
    },
    { //SUPPORT
        path: '/support/',
        name: 'Support',
        component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
        meta: {
            visible: ['partner', 'ma'],
        }
    },
    { //Support
        path: '/support/:id:/',
        name: 'Support_Detail',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Support_Detail.vue'),
        meta: {
            visible: [],
        }
    },
    /*{ //PioneerInitiative
    path: '/pioneer-initiative/',
        name: 'Pioneer_Initiative',
        component: () => import(/* webpackChunkName: "tickets" *//* '../views/Pioneer_Initiative.vue'),
        meta: {
            visible: [],
        }
    }, */
    { path: '/pioneer-initiative', 
        redirect: '/' },
    { //LoxoneConferenceStats
    path: '/conference-stats/',
        name: 'Conference_Stats',
        component: () => import(/* webpackChunkName: "ConferenceStats" */ '../views/ConferenceStats.vue'),
        meta: {
            visible: ['ma'],
        }
    },
    { //CampusPackages
        path: '/campus-packages/',
        name: 'Campus_Packages',
        component: () => import(/* webpackChunkName: "CampusPackages" */ '../views/Campus_Packages.vue'),
        meta: {
            visible: [],
        }
    },
    { //Err_Access
        path: "/no-access/",
        name: 'Err_Rights',
        component: () => import(/* webpackChunkName: "support" */ '../views/Err_Rights.vue'),
        meta: {
            visible: [],
        }
    },
    { //Err_404
        path: "/:pathMatch(.*)*",
        name: 'Err_404',
        component: () => import(/* webpackChunkName: "support" */ '../views/Err_404.vue'),
        meta: {
            visible: [],
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
const urlParams = window.location.search;
router.beforeEach((to, from, next) => {
    if(!store.state.userData.loaded) {
        axios({
            method: 'post',
            withCredentials: true,
            crossDomain: true,
            url: '/api/checkUserSession',
            baseURL: window.location.origin == 'https://portal.loxone.com' ? 'https://portal.loxone.com' : 'https://dev-portal.loxone.com',
        })
        .then(resp => {
            if(to.name != 'Login' && to.name != 'PasswordReset' && to.name != 'Registration' && to.name != 'MessageCenter' && to.name != 'MessageDetail' && to.name != 'Registration_new') {
                if(!resp.data.loggedIn) {
                    next(`/login${urlParams}`);
                    
                }
            }
            if (resp.data && resp.data.valid) {
                store.state.loggedIn = resp.data.valid;
                if (resp.data.userData) {
                    store.state.userData.content = resp.data.userData;
                    store.state.userData.loaded = true;

                    if(store.state?.userData?.content?.partner_status?.includes('REG') || store.state?.userData?.content?.reg_flex_enabled == 1) {
                        store.state.isStartingPartner = true;
                    } else {
                        store.state.isStartingPartner = false;
                    }
                }
            }

            if(to.meta.visible.length == 0 || to.meta.visible.filter(x => store.state.userGroups.includes(x)).length > 0){
                next();
            } else {
                next(`/no-access${urlParams}/`);
            }
        });
    } else {
        if(to.meta.visible.length == 0 || to.meta.visible.filter(x => store.state.userGroups.includes(x)).length > 0){
            next();
        } else {
            next(`/no-access${urlParams}/`);
        }
    }
});


export default router
